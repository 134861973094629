import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A key feature of Lua is its simplicity and ease of use. It has a clean syntax that is easy to read and write, and it is designed to be embedded into larger programs as a scripting language. It supports a wide range of programming paradigms, including functional programming and object-oriented programming.`}</p>
    <p>{`Lua’s flexibility makes it well-suited to a wide range of applications, from small embedded systems to large-scale applications. It can easily be integrated into other languages and frameworks, such as C and C++, making it a popular choice for game developers and other software engineers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      